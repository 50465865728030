<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h4>Catégories</h4>

        <Toolbar class="mb-4" v-if="Permissions.add">
          <template v-slot:start>
            <div class="my-2">
              <Button
                label="Ajouter un nouveau Catégorie"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                @click="showAddCLient()"
              />
            </div>
          </template>
        </Toolbar>
        <DataTable
          :value="categories"
          :paginator="true"
          class="p-datatable-gridlines"
          :rows="10"
          dataKey="id"
          :rowHover="true"
          v-model:filters="filters1"
          :loading="loading1"
          :filters="filters1"
          responsiveLayout="scroll"
          :globalFilterFields="['nomCategorie', 'descriptionCategorie', 'id']"
        >
          <template #header>
            <div class="flex justify-content-between flex-column sm:flex-row">
              <span class="p-input-icon-left mb-2">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters1['global'].value"
                  placeholder="Keyword Search"
                  style="width: 100%"
                />
              </span>
            </div>
          </template>
          <template #empty> Aucun catégorie trouvé. </template>
          <template #loading>
            Chargement des données catégories. Veuillez patienter.
          </template>
          <Column
            field="id"
            header="#ID"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.id }}
            </template>
          </Column>
          <Column
            field="nomCategorie"
            header="Catégorie"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.nomCategorie }}
            </template>
          </Column>
          <Column
            field="descriptionCategorie"
            header="Description"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.descriptionCategorie }}
            </template>
          </Column>

          <Column header="Actions" style="min-width: 12rem">
            <template #body="data">
              <span class="p-buttonset">
                <!-- <Button
                  @click="showclientById(data.data.id)"
                  style="margin-right: 6px"
                  icon="pi pi-eye"
                  class="p-button-raised p-button-rounded p-button-success"
                /> -->
                <Button
                  v-if="Permissions.update"
                  style="margin-right: 6px"
                  icon="pi pi-user-edit"
                  class="p-button-raised p-button-rounded p-button-info"
                  @click="editCategorie(data.data)"
                />
                <Button
                  v-if="Permissions.delete"
                  icon="pi pi-trash"
                  class="p-button-raised p-button-rounded p-button-danger"
                  @click="deleteCategorie(data.data.id)"
                />
              </span>
            </template>
          </Column>
        </DataTable>
        <Dialog
          v-model:visible="dialogOfAdd"
          :style="{ width: '900px' }"
          header="Ajouter une Catégorie"
          :modal="true"
          class="p-fluid"
        >
          <div class="card">
            <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-12">
                <label for="firstname2">Catégorie </label>

                <InputText
                  v-model="categorieToAdd.nomCategorie"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['nomCategorie'] }"
                />
                <small class="p-error" v-if="errorsAdd['nomCategorie']">
                  {{ errorsAdd["nomCategorie"] }}
                </small>
              </div>
              <div class="field col-12 md:col-12">
                <label for="lastname2">Description </label>
                <InputText
                  v-model="categorieToAdd.descriptionCategorie"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['descriptionCategorie'] }"
                />
                <small class="p-error" v-if="errorsAdd['descriptionCategorie']">
                  {{ errorsAdd["descriptionCategorie"] }}
                </small>
              </div>
            </div>
          </div>
          <template #footer>
            <Button
              label="Annuler"
              icon="pi pi-times"
              class="p-button-text"
              @click="dialogOfAdd = false"
            />
            <Button
              label="ajouter"
              icon="pi pi-check"
              class="p-button-text"
              @click="checkFormAdd"
            />
          </template>
        </Dialog>
        <Dialog
          v-model:visible="dialogOfEdit"
          :style="{ width: '900px' }"
          header="Modifier"
          :modal="true"
          class="p-fluid"
        >
          <div class="card">
            <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-12">
                <label for="firstname2">Catégorie </label>

                <InputText
                  v-model="categorieToAdd.nomCategorie"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['nomCategorie'] }"
                />
                <small class="p-error" v-if="errorsAdd['nomCategorie']">
                  {{ errorsAdd["nomCategorie"] }}
                </small>
              </div>
              <div class="field col-12 md:col-12">
                <label for="lastname2">Description </label>
                <InputText
                  v-model="categorieToAdd.descriptionCategorie"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['descriptionCategorie'] }"
                />
                <small class="p-error" v-if="errorsAdd['descriptionCategorie']">
                  {{ errorsAdd["descriptionCategorie"] }}
                </small>
              </div>
            </div>
          </div>
          <template #footer>
            <Button
              label="Annuler"
              icon="pi pi-times"
              class="p-button-text"
              @click="dialogOfEdit = false"
            />
            <Button
              label="Modifier"
              icon="pi pi-check"
              class="p-button-text"
              @click="checkFormAdd"
            />
          </template>
        </Dialog>
        <ConfirmDialog></ConfirmDialog>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { FilterMatchMode, FilterOperator } from "primevue/api";
export default {
  data() {
    return {
      Permissions: { update: false, delete: false, add: false, show: false },
      categories: [],
      filters1: null,
      loading1: true,

      dialogOfShow: false,
      clientToShow: {},

      dialogOfEdit: false,
      updatesubmitted: false,

      dialogOfAdd: false,

      categorieToAdd: {
        id: null,
        nomCategorie: null,
        descriptionCategorie: null,
      },

      errorsAdd: [],
      multiselectValueAdd: [],
      feature_id: null,
    };
  },

  mounted() {
    this.Permissions.update =
      localStorage.permissionNames.includes("Modifier categorie");
    this.Permissions.delete = localStorage.permissionNames.includes(
      "Supprimer categorie"
    );
    this.Permissions.add =
      localStorage.permissionNames.includes("Ajouter categorie");
    this.Permissions.show =
      localStorage.permissionNames.includes("Liste categorie");
    if (this.Permissions.show) {
      this.showAllCategorie();
    } else {
      this.$router.push("/");
    }
  },

  created() {
    // if (localStorage.token == "" || localStorage.token == null) {
    //   this.$router.push("/login");
    // }
    this.initFilters1();
  },
  methods: {
    deleteCategorie(id) {
      this.$swal({
        icon: "warning",
        title: "Vous êtes sûr de vouloir continuer ?",
        showDenyButton: true,
        confirmButtonText: "supprimer",
        denyButtonText: `Annuler`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios
            .post("supprimerCategorie/" + id)
            .then(() => {
              var index = this.categories
                .map((x) => {
                  return x.id;
                })
                .indexOf(id);
              this.categories.splice(index, 1);
              console.log(this.categories);
              this.$swal({
                icon: "success",
                title: "Supprimé",
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
        }
      });
    },
    updateCategorie() {
      axios
        .post(
          "modifierCategorie/" + this.categorieToAdd.id,
          this.categorieToAdd
        )
        .then((response) => {
          this.showAllCategorie();
          this.$swal({
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
          this.dialogOfEdit = false;
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },

    initformadd() {
      this.categorieToAdd.nomCategorie = null;
      this.categorieToAdd.descriptionCategorie = null;
    },
    showAllCategorie() {
      axios
        .get("listeCategorie")
        .then((response) => {
          this.categories = response.data.categories;
          this.loading1 = false;
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },
    addClient() {
      axios
        .post("ajouterCategorie", this.categorieToAdd)
        .then((response) => {
          console.log(response);
          this.showAllCategorie();
          this.$swal({
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
          this.dialogOfAdd = false;
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },
    showAddCLient() {
      this.initformadd();
      this.errorsAdd = [];
      this.dialogOfAdd = true;
    },
    checkFormAdd() {
      let checked = true;
      this.errorsAdd = [];

      if (
        this.categorieToAdd.descriptionCategorie == null ||
        this.categorieToAdd.descriptionCategorie == ""
      ) {
        this.errorsAdd["nom"] = "Description est obligatoire.";
        checked = false;
      }
      if (
        this.categorieToAdd.nomCategorie == null ||
        this.categorieToAdd.nomCategorie == ""
      ) {
        this.errorsAdd["gsm"] = "Catégorie est obligatoire.";
        checked = false;
      }

      if (checked) {
        if (this.dialogOfAdd) {
          this.addClient();
        } else {
          this.updateCategorie();
        }
      }
    },

    editCategorie(data) {
      this.initformadd();
      this.categorieToAdd.id = data.id;
      this.categorieToAdd.nomCategorie = data.nomCategorie;
      this.categorieToAdd.descriptionCategorie = data.descriptionCategorie;
      this.dialogOfEdit = true;
    },

    showclientById(id) {
      axios
        .get("getClientByid/" + id)
        .then((response) => {
          this.clientToShow = response.data.categories;
          this.technicienAffecte = response.data.technicienAffecte;

          console.log("this.clientToShow", response);
          console.log("this.technicienAffecte", this.technicienAffecte);
          this.dialogOfShow = true;
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },

    initFilters1() {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        "country.name": {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        date: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
        },
        balance: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        status: {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
      };
    },
    clearFilter1() {
      this.initFilters1();
    },

    formatCurrency(value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    formatDate(value) {
      return value.toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    },
    calculateCustomerTotal(name) {
      let total = 0;
      if (this.customer3) {
        for (let customer of this.customer3) {
          if (customer.representative.name === name) {
            total++;
          }
        }
      }

      return total;
    },
  },
};
</script>
<style scoped lang="scss">
@import "../assets/demo/badges.scss";
@import "../assets/demo/badges.scss";
::v-deep(.p-datatable-frozen-tbody) {
  font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
  font-weight: bold;
}
.is-invalid {
  border-color: #e24c4c;
}
.p-inputtext.p-invalid.p-component {
  border-color: #e24c4c;
}
</style>